import React from "react"
import medicare from "../images/medicare.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical, faHospital, faPills, faUserMd } from "@fortawesome/free-solid-svg-icons";

const Medicare = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={medicare} alt="liability..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Medicare</h1>
                        <p>
                        Inscribirse en Medicare puede ser un proceso confuso y frustrante. Estamos comprometidos a guiarlo a través de ese proceso. 
                        Nuestro trabajo es ayudarlo a encontrar las mejores opciones de cobertura más rentables para usted y con la atención que necesita.
                        </p>
                        <h2>Sus Opciones de Medicare</h2>
                        <br></br>
                        <h3>Medicare Original</h3>
                        <p>Medicare original incluye</p>
                        <p>&#9642; Parte A (Seguro de Hospital) y Parte B (Seguro Médico) de Medicare.</p>
                        <p>&#9642; Si desea la cobertura de medicamentos de Medicare (Parte D), deberá inscribirse en un plan separado.</p>
                        <p>&#9642; Es posible que también deba comprar una cobertura complementaria para ayudarlo con los costos de su bolsillo en Medicare Original (como un coseguro del 20 %).</p>
                        <p>&#9642; Puede usar cualquier médico u hospital que acepte Medicare en cualquier parte de los Estados Unidos.</p>
                        <div className="circle"><FontAwesomeIcon className="icon" icon={faUserMd} size="2x" />  Parte A</div>
                        <div className="circle"><FontAwesomeIcon className="icon" icon={faHospital} size="2x" />  Parte B</div>
                        <br></br>
                        <p>Podria añadir a su Medicare Original:</p>
                        <div className="circle">&#9642;<FontAwesomeIcon className="icon" icon={faPills} size="2x" />  Parte D</div>
                        <br></br>
                        <p>También puedria agregar:</p>
                        <div className="circle">&#9642;<FontAwesomeIcon className="icon" icon={faBriefcaseMedical} size="2x" />  Cobertura suplementaria</div>
                        <p>Incluye Seguro Complementario (Medigap). O bien, puede utilizar la cobertura de un antiguo empleador, sindicato o Medicaid.</p>
                        <br></br>
                        <br></br>
                        <h3>Medicare Advantage</h3>
                        <p>(conocido como Parte C)</p>
                        <p>&#9642; Medicare Advantage es un plan aprobado por Medicare de una compañía privada que ofrece una alternativa al Medicare Original para su cobertura de salud y medicamentos. 
                        Este “conjunto” de planes incluye las Partes A, B y, por lo general, la Parte D.</p>
                        <p>&#9642; En la mayoría de los casos, debe usar médicos que estén en la red del plan.</p>
                        <p>&#9642; Los planes pueden tener costos de bolsillo más bajos que el Medicare Original.</p>
                        <p>&#9642; Los planes pueden ofrecer algunos beneficios adicionales que Medicare Original no cubre, como servicios dentales, de la vista y auditivos.</p>
                        <div className="circle"><FontAwesomeIcon className="icon" icon={faUserMd} size="2x" />  Parte A</div>
                        <div className="circle"><FontAwesomeIcon className="icon" icon={faHospital} size="2x" />  Parte B</div>
                        <br></br>
                        <p>La mayoría de los planes incluyen:</p>
                        <p className="circle">&#9642; <FontAwesomeIcon className="icon" icon={faPills} size="2x" />  Parte D</p>
                        <p>&#9642; Algunos beneficios adicionales.</p>
                        <p>&#9642; Costos más bajos/gastos de bolsillo.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Medicare;