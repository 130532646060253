import React from "react"
import myself from "../images/insurance1.jpeg";

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="photo-wrap mb-5">
                            <img className="profile-img" src={myself} alt="myself..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="about-heading">Sobre nosotros</h1>
                        <p>
                        Somos una agencia independiente que se especializa en opciones de planes de Medicare y planes de seguro de salud individuales.
                        </p>
                        <p>
                        Proteger su salud y la de su familia es nuestra mayor prioridad. Nuestros especialistas pueden ayudarlo a elegir el 
                        paquete de seguro de salud adecuado para satisfacer sus necesidades y las de su familia. No consideramos nuestros servicios como “ventas”.
                        </p>
                        <p>
                        Llame a nuestra oficina hoy y descubra como nuestros expertos en seguros pueden ayudarle a usted y su familia.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs