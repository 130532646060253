import React from "react"
import complementary from "../images/complementary.jpeg";

const Vida = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={complementary} alt="rv..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Complementarios</h1>
                        <p>
                        Darse cuenta de que necesita tener un seguro complementario para cubrir gastos extras no cubiertos por su cobertura medica principal y/o necesitar un seguro de vida puede ser difícil para algunas personas. 
                        Después de todo, usted está discutiendo temas que son a largo plazo en el futuro. Hablar de las cosas con su pareja, un miembro de la familia o incluso un amigo cercano puede ayudarlo a resolver sus problemas.
                        </p>
                        <p>Usted puede mirar las cotizaciones todo el día, pero si no sabe por qué necesita cobertura, no podrá elegir la póliza correcta. Las personas revisan sus cotizaciones de seguros de vida y comienzan una póliza porque quieren asegurarse de que su familia esté cubierta en caso de que les suceda algo. 
                        Esto no solo cubre el costo relacionado con un funeral, sino también el apoyo financiero durante años.</p>
                        <p>Cuando observa sus pólizas, debe pensar en cuánto dinero necesitaría para vivir en función de su salario actual. Luego intenta hacer las inversiones adecuadas para ganar esa cantidad de dinero durante uno, cinco, diez o más años. Debido a que tomó la iniciativa de abrir una póliza, 
                        su familia puede pagar la hipoteca, la deuda e incluso iniciar un fondo de educación universitaria.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vida;