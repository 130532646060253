import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical, faBuilding, faCar, faClinicMedical, faClipboardList, faHouseDamage, faStarOfLife, faTooth } from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import history from './../history';
import "../App.css";

const Services = () => {
    return (
        <div className="services">
          <h1 className="py-5">nuestros seguros</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faClinicMedical} size="2x" /></div>
                    <h3>ACA</h3>
                    <p>La Ley para la Protección de Pacientes y Cuidados de la Salud Asequible (The Patient Protection and Affordable Care Act) conocida como Obamacare, promulgada en el ano 2010, 
                    ofrece cobertura y planes de salud basados en las necesidades de cada individuo o grupo familiar...</p><br></br>
                    {/* <Link smooth={true}  onClick={() => ('/Auto')} offset={-110} className="btn" href="#">Read More</Link> */}
                    <Button variant="btn btn-success" onClick={() => history.push('/ACA')}>Leer Más</Button>
                </div>
              </div>

              

              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faBriefcaseMedical} size="2x" /></div>
                    <h3>Medicare</h3>
                    <p>Inscribirse en Medicare puede ser un proceso confuso y frustrante. Estamos comprometidos a guiarlo a través de ese proceso. 
                    Nuestro trabajo es ayudarlo a encontrar las mejores opciones de cobertura más rentables para usted y con la atención que necesita...</p><br></br>
                  {/* <Link smooth={true} to="footer" offset={-110} className="btn" href="#">Read More</Link> */}
                  <Button variant="btn btn-success" onClick={() => history.push('/Medicare')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faTooth} size="2x" /></div>
                  <h3>Dental</h3>
                  <p>Cuidar bien de tu salud significa preocuparte por tu salud bucal, tengas la edad que tengas. 
                  Nuestras pólizas de seguro dental son solo una forma más fácil de ayudarlo a mantenerse saludable...</p><br></br><br></br><br></br>
                      {/* <Link smooth={true} to="footer" offset={-110} className="btn" href="#">Read More</Link> */}
                      <Button variant="btn btn-success" onClick={() => history.push('/Dental')}>Leer Más</Button>
                </div>
              </div>
              {/* - */}
              <div className="col-md-3 col-sm-6">
                <div className="box">
                  <div className="circle"><FontAwesomeIcon className="icon" icon={faStarOfLife} size="2x" /></div>
                  <h3>Compementarios</h3>
                  <p>Darse cuenta de que necesita tener un seguro complementario para cubrir gastos extras no cubiertos por su cobertura medica principal y/o necesitar un seguro de vida puede ser difícil para algunas personas. 
                  Después de todo, usted está discutiendo temas que son a largo plazo en el futuro...</p>
                    {/* <Link smooth={true} to="footer" offset={-110} className="btn" href="#">Read More</Link> */}
                    <Button variant="btn btn-success" onClick={() => history.push('/Vida')}>Leer Más</Button>
                  </div>
              </div>
            </div>
          </div>
        </div>
    )

  }
export default Services;