import React, { Component } from 'react';
import aca from "../images/ACA.jpeg";

const ACA = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={aca} alt="car..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Seguros ACA</h1>
                        <p>
                        La Ley para la Protección de Pacientes y Cuidados de la Salud Asequible (The Patient Protection and Affordable Care Act) conocida como Obamacare, promulgada en el ano 2010, 
                        ofrece cobertura y planes de salud basados en las necesidades de cada individuo o grupo familiar.
                        </p>
                        <p>Estamos presentes en más de 35 estados del país y representamos a más de 30 de las principales compañías de seguros de salud de Estados Unidos, podemos ayudarlo a encontrar el plan que mejor se adapte a sus necesidades y presupuesto. 
                        Ya sea que esté buscando una cotización GRATUITA rápida y sin complicaciones, o una conversación detallada sobre todas sus opciones, con nuestra experiencia podemos ayudarlos.</p>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default ACA;