import React from "react"
import dental from "../images/dental.jpeg";

const Dental = () => {
    return (
        <div className="pages">
            <div className="container">
                <div className= "row">
                    <div className= "col-lg-6 col-xm-12">
                        <div className="pages-photo-wrap mb-5">
                            <img className="pages-profile-img" src={dental} alt="house..."/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xm-12">
                        <h1 className="pages-heading">Seguro Dental</h1>
                        <p>
                        Cuidar bien de tu salud significa preocuparte por tu salud bucal, tengas la edad que tengas. Nuestras pólizas de seguro dental son solo una forma más fácil de ayudarlo a mantenerse saludable. Visitar al dentista de manera regular le traera beneficios en su salud.
                        </p>
                        <p>Ir al dentista no es algo que la mayoría de la gente desee. No siempre es una experiencia agradable y puede causar ansiedad en algunos.</p>
                        <p>&#9642; Detecte los problemas antes de que se vuelvan dolorosos o costosos.</p>
                        <p>&#9642; Construcción de una relación con su equipo de atención dental.</p>
                        <p>&#9642; Tome decisiones informadas. ¡Deja al dentista sintiéndote mejor!.</p>
                        <p>&#9642; Mantenerse al tanto de su salud dental y general Aproveche los servicios preventivos.</p>
                        <p>&#9642; Aproveche los servicios preventivos.</p>
                        <p>&#9642; Existe una fuerte relación entre la salud bucal y la salud en general. Cuando obtiene su cobertura dental y de salud, 
                        puede beneficiarse de programas dentales y de salud integrados que pueden mejorar su bienestar total.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dental;