import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import ACA from "../src/pages/ACA";
import Medicare from "./pages/Medicare";
import Dental from "../src/pages/Dental";
import Vida from "./pages/Vida";
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    {/* <Route path="/" exact component={Auto} /> */}
                    <Route path="/ACA" component={ACA} />
                    <Route path="/Medicare" component={Medicare} />
                    <Route path="/Dental" component={Dental} />
                    <Route path="/Vida" component={Vida} />
                </Switch>
            </Router>
        )
    }
}
