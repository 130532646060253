import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

const ContactsUs = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const { register, handleSubmit, errors } = useForm();

  const serviceID = "service_89k8448";
  const templateID = "template_buxq3py";
  const userID = "user_lZ69vnvSN2wKY9EG9zFlK";

  const onSubmit = (data, r) => {
    sendEmail(
      serviceID,
      templateID,
      {
        name: data.name,
        email: data.email,
        phone: data.phone,
        // zipcode: data.zipcode,
        // type: data.type,
        message: data.message
      },
      userID
    )
    r.target.reset();
  }

  const sendEmail = (serviceID, templateID, variables, userID) => {
    emailjs.send(serviceID, templateID, variables, userID)
      .then(() => {
        setSuccessMessage("¡Formulario enviado con éxito! Nos pondrémos en contacto con usted tan pronto como sea posible.");
      }).catch(err => console.error(`Algo salió mal ${err}`));
  }

  return (
    <div id="contact" className="contacts">
      <div className="text-center">
      <h1>contáctanos</h1>
      <p>Complete el formulario y describa sus necesidades, y nos pondremos en contacto con usted lo antes posible.</p>
        <span className="success-message">{successMessage}</span>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {/* NAME INPUT */}
              <span className="error-message">
                {errors.name && errors.name.message}
              </span>
              <div className="text-center">
              <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  name="name"
                  ref={
                    register({
                      required: "Por favor, escriba su nombre",
                      maxLength: {
                        value: 20,
                        message: "Por favor ingrese un nombre con menos de 20 caracteres"
                      }
                    })
                  }
                />
                <div className="line"></div>
              </div>
              {/* PHONE INPUT */}
              <span className="error-message">
                {errors.phone && errors.phone.message}
              </span>
              <div className="text-center">
              <input
                  type="text"
                  className="form-control"
                  placeholder="Teléfono"
                  name="phone"
                  ref={
                    register({
                      required: "Por favor, introduzca su número de teléfono",
                      maxLength: {
                        value: 10,
                        message: "Ingrese el número de teléfono, incluido su código de área - Máximo 10 números"
                      }
                    })
                  }
                />
                <div className="line"></div>
              </div>
              {/* ZIPCODE INPUT */}
              <span className="error-message">
                {errors.zipcode && errors.zipcode.message}
              </span>
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Código postal"
                  name="zipcode"
                  ref={
                    register({
                      required: "Por favor ingrese su código postal",
                      maxLength: {
                        value: 5,
                        message: "Ingrese su código postal - Solo 5 caracteres"
                      }
                    })
                  }
                />
                <div className="line"></div>
              </div>
              {/* Type INPUT */}
              <span className="error-message">
                {errors.type && errors.type.message}
              </span>
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tipo de seguro" 
                  name="type"                 
                  ref={
                    register({
                      required: "Seleccione su tipo de seguro para cotizar",
                      maxLength: {
                        value: 20,
                        message: "Seleccione su tipo de seguro para cotizar"
                      }
                    })
                  }
                />
                <div className="line"></div>
              </div>
              {/* EMAIL INPUT */}
              <span className="error-message">
                {errors.email && errors.email.message}
              </span>
              <div className="text-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  ref={
                    register({
                      required: "Por favor proporcione su correo electrónico",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "email inválido"
                      }
                    })
                  }
                />
                <div className="line"></div>
              </div>
              {/* MESSAGE */}
              <span className="error-message">
                {errors.message && errors.message.message}
              </span>
              <div className="text-center">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Por favor, deje un mensaje corto..."
                  name="message"
                  ref={
                    register({
                      required: "Por favor, deje un mensaje corto...",
                    })
                  }
                ></textarea>
                <div className="line"></div>
              </div>
              <button className="btn-main-offer contact-btn" type="submit">contáctanos</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactsUs;