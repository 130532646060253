import React, { Component } from 'react';
import '../App.css';
import USAMap from "react-usa-map";

class Maps extends Component {
  mapHandler = (event) => {
    alert(event.target.dataset.name);
  };

  statesFilling = () => {
    return {
      "NJ": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en New Jersey para Obamacare: AETNA , AMBETTER, AMERIHEALTH, BLUECROSS, OSCAR. \nEmpresas disponibles en New Jersey para Medicare: HUMANA")
      },
      "NY": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en New York para Obamacare: AEMBLEMHEALTH, EMPIRE / BLUE CROSS BLUE SHIELD, MVP HEALTHCARE, OSCAR")
      },
      "FL": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Florida para Obamacare: AETNA, AMBETTER, AVMED, CIGNA, FLORIDA BLUE, FLORIDA HEALTHCARE PLANS, HEALTH FIRST, MOLINA, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Florida para Medicare: AETNA, DEVOTED, CIGNA, HUMANA")
      },
      "AL": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Alabama para Obamacare: UNITED HEALTHCARE. \nEmpresas disponibles en Alabama para Medicare: AETNA, DEVOTED, CIGNA, HUMANA"),                  
      },
      "CA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en California para Obamacare: KAISER PERMANENTE, MOLINA, OSCAR, AETNA. \nEmpresas disponibles en California para Medicare: Humana")
      },
      "CO": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Colorado para Obamacare: CIGNA, FRIDAY, KAISER PERMANENTE, OSCAR, ROCKY MOUNTAIN HEALTH PLANS, UNITED HEALTHCARE. \nEmpresas disponibles en Colorado para Medicare: AETNA, DEVOTED, CIGNA, HUMANA.")
      },
      "AZ": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Arizona para Obamacare: AETNA, AMBETTER, CIGNA, MEDICA, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Arizona para Medicare: AETNA, DEVOTED, CIGNA, HUMANA.")
      },
      "DE": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Delaware para Obamacare: AETNA , HIGHMARK. \nEmpresas disponibles en Delaware para Medicare: CIGNA, HUMANA.")
      },
      "GA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Georgia para Obamacare: ALLIANT, AMBETTER, BLUECROSS, CARESOURCE, CIGNA, FRIDAY, KAISER PERMANENTE, OSCAR, UNITED HEALTHCARE, AETNA. \nEmpresas disponibles en Georgia para Medicare: AETNA, CIGNA, HUMANA.")
      },
      "HI": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Hawaii para Obamacare: KAISER PERMANENTE. \nEmpresas disponibles en Hawaii para Medicare: AETNA, DEVOTED.")
      },
      "IL": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Illinois para Obamacare: AMBETTER, BLUECROSS, CIGNA, HEALTH ALLIANCE, MOLINA, OSCAR, UNITED HEALTHCARE, AETNA. \nEmpresas disponibles en Illinois para Medicare: HUMANA.")
      },
      "IN": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Indiana para Obamacare: AMBETTER, ASCENSION, CARESOURCE, CIGNA, US HEALTH & LIFE. \nEmpresas disponibles en Indiana para Medicare: AETNA, HUMANA.")
      },
      "IA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Iowa para Obamacare: OSCAR, MEDICA. \nEmpresas disponibles en Iowa para Medicare: AETNA, HUMANA.")
      },
      "KS": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Kansas para Obamacare: AMBETTER, ASCENSION, CIGNA, MEDICA, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Kansas para Medicare:AETNA, CIGNA, HUMANA.")
      },
      "KY": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Kentucky para Obamacare: CARESOURCE, MOLINA, AMBETTER. \nEmpresas disponibles en Kentucky para Medicare: AETNA, CIGNA")
      },
      "LA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Louisiana para Obamacare: AMBETTER, CHRISTUS HEALTH, UNITED HEALTHCARE. \nEmpresas disponibles en Louisiana para Medicare: AETNA, CIGNA")
      },
      "MD": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Maryland para Obamacare: UNITED HEALTHCARE, KAISER PERMANENTE. \nEmpresas disponibles en Maryland para Medicare: AETNA, CIGNA, HUMANA.")
      },      
      "MI": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Michigan para Obamacare: AMBETTER, ASCENSION, MOLINA, OSCAR, PRIORITY HEALTH, UNITED HEALTHCARE. \nEmpresas disponibles en Michigan para Medicare: AETNA, HUMANA.")
      },
      "MN": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Minnesota para Obamacare: MEDICA. \nEmpresas disponibles en Maryland para Medicare: HUMANA.")
      },
      "MS": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Mississippi para Obamacare: MOLINA, UNITED HEALTHCARE, AMBETTER. \nEmpresas disponibles en Mississippi para Medicare: AETNA, CIGNA, HUMANA.")
      },
      "MO": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Missouri para Obamacare: AETNA, AMBETTER, CIGNA, MEDICA, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Missouri para Medicare: AETNA, CIGNA, HUMANA.")
      },
      "NE": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Nebraska para Obamacare: MEDICA, OSCAR,AMBETTER. \nEmpresas disponibles en Nebraska para Medicare: AETNA.")
      },
      "NM": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en New Mexico para Obamacare: FRIDAY, MOLINA, PRESBYTERIAN, AMBETTER. \nEmpresas disponibles en New Mexico para Medicare: AETNA, CIGNA.")
      },
      "NC": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en North Carolina para Obamacare: AMBETTER, CIGNA, FRIDAY, OSCAR, UNITED HEALTHCARE, AETNA. \nEmpresas disponibles en North Carolina para Medicare: DEVOTED, CIGNA, HUMANA.")
      },
      "OH": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Ohio para Obamacare: AMBETTER, CARESOURCE, MOLINA, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Ohio para Medicare: DEVOTED, CIGNA, HUMANA.")
      },
      "OK": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Oklahoma para Obamacare: BLUECROSS, FRIDAY, MEDICA, OSCAR, UNITED HEALTHCARE, AMBETTER. \nEmpresas disponibles en Oklahoma para Medicare: AETNA, HUMANA.")
      },
      "OR": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Oregon para Obamacare: KAISER PERMANENTE. \nEmpresas disponibles en Nebraska para Oregon: DEVOTED, HUMANA.")
      },
      "PA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Pennsylvania para Obamacare: CIGNA, HIGHMARK, OSCAR, AMBETTER. \nEmpresas disponibles en Pennsylvania para Medicare: AETNA, HUMANA, DEVOTED.")
      },
      "SC": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en South Carolina para Obamacare: AMBETTER, CIGNA, MOLINA. \nEmpresas disponibles en South Carolina para Medicare: AETNA, CIGNA, HUMANA, DEVOTED.")
      },
      "TN": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Tennessee para Obamacare: AMBETTER, ASCENSION, CIGNA, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Tennessee para Medicare: AETNA, CIGNA, HUMANA, DEVOTED.")
      },
      "TX": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Texas para Obamacare: UNITED HEALTHCARE, AETNA, AMBETTER, ASCENSION, BLUECROSS, CHRISTUS HEALTH, CIGNA, FIRST CARE, MOLINA, OSCAR, SCOTT & WHITE HEALTH PLAN. \nEmpresas disponibles en Texas para Medicare: AETNA, CIGNA, HUMANA, DEVOTED.")
      },
      "UT": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Utah para Obamacare: CIGNA, MOLINA. \nEmpresas disponibles en uTAH para Medicare: CIGNA, HUMANA, AETNA.")
      },
      "VA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Virginia para Obamacare: AETNA, CIGNA, KAISER PERMANENTE, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Virginia para Medicare: AETNA, CIGNA, KAISER PERMANENTE, OSCAR, UNITED HEALTHCARE. \nEmpresas disponibles en Nebraska para Medicare: AETNA, HUMANA.")
      },
      "WA": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Washington para Obamacare: MOLINA, KAISER PERMANENTE NORTHWEST. \nEmpresas disponibles en Washington para Medicare: CIGNA, HUMANA.")
      },
      "WI": {
        fill: "#CC0000",
        clickHandler: () => alert("Empresas disponibles en Wisconsin para Obamacare: MEDICA. \nEmpresas disponibles en Wisconsin para Medicare: HUMANA, AETNA.")
      }
    };
  };

  render() {
    return (
      <div className="maps">
        <h1 className="py-5">cobertura</h1>
        <div className="container">
            <div className= "row">
              <div className="maps-photo-wrap mb-5">
                  <USAMap customize={this.statesFilling()} onClick={this.mapHandler} />
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Maps;